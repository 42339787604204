<template>
    <!--检索结果-->
    <div class="page dyygHome retrievedResult">
        <div class="srhBox flex_c_c">
            <el-input class="srhIpt" v-model.trim="srhStr" clearable maxlength="100"
                      placeholder="请输入名称、关键词等" @keyup.enter.native="search(1)"></el-input>
            <button class="srhBtn mainBdColor mainBgColor" @click="search(1)"><i class="el-icon-search"></i>搜索</button>
        </div>
        <div class="plateBox">
            <el-tabs v-model="tabType" class="m_b1" @tab-click="clearAll()">
                <el-tab-pane label="资源" name="0"></el-tab-pane>
                <el-tab-pane label="文件" name="1"></el-tab-pane>
            </el-tabs>
            <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>数据筛选</span></div>
            <div class="flex_b_t queryItem">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">资源类型：</div>
                    <div class="flex1">
                        <div :class="['unlimited pointer i_block m_b1', unlimitedFlag ? 'mainBgColor whiteColor': 'resetColor']"
                             @click="selUnlimited">不限
                        </div>
                        <el-checkbox-group v-model="resourceCheckList" @change="resourceChange">
                            <el-checkbox :label="item.value"
                                         v-for="(item, index) in $dictUtils.getDictList('resource_type')" :key="index"
                                         :disabled="loading">
                                {{ item.label }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <div class="flex_b_t queryItem" v-show="tabType == 0">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">资源属性：</div>
                    <div class="flex1">
                        <el-radio-group v-model="radio" @input="attributeType()">
                            <el-radio :label="'0'">全部</el-radio>
                            <el-radio :label="'10'">藏品资源</el-radio>
                            <el-radio :label="'11'">非藏品资源</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="flex_b_t queryItem" v-show="tabType == 1">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">文件类型：</div>
                    <div class="flex1 fileTypeBox">
                        <div :class="['unlimited pointer i_block m_b1', fileTypeFlag ? 'mainBgColor whiteColor': 'resetColor']"
                             @click="selFileTypeFlag">不限
                        </div>
                        <div ref="fileTypeUnfoldBox">
                            <el-checkbox-group :class="fileTypeUnfold ? 'unfoldBox':'unfoldBox2'" v-model="fileTypeCheckList" @change="fileTypeChange">
                                <el-checkbox :label="item" v-for="(item, index) in fileTypeOpt" :key="index"
                                             :disabled="loading">
                                    {{ item }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <div class="arrowBox text_center" v-show="fileTypeOpt.length > 20">
                            <el-link :icon="fileTypeUnfold ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :underline="false" @click="fileTypeUnfold = !fileTypeUnfold">{{fileTypeUnfold ? '收起': '展开'}}</el-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex_b_t queryItem">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">已选条件：</div>
                    <div class="flex1" v-show="tabType == 0">
                        <el-tag effect="plain" closable v-for="(item, index) in resourceCheckList" :key="index"
                                @close="closeConditions(item)">
                            {{ item == 10 ? '藏品资源' : item == 11 ? '非藏品资源' : $dictUtils.getDictLabel("resource_type",
                            item, '') }}
                        </el-tag>
                    </div>
                    <div class="flex1" v-show="tabType == 1">
                        <div :class="conditionsUnfold ? 'unfoldBox':'unfoldBox3'">
                            <div ref="conditionsUnfoldBox">
                                <el-tag effect="plain" closable v-for="(item, index) in resourceCheckList" :key="item"
                                        @close="closeFileConditions(item, 'resource')">
                                    {{ $dictUtils.getDictLabel("resource_type", item, '') }}
                                </el-tag>
                                <el-tag effect="plain" closable v-for="(item, index) in fileTypeCheckList" :key="item"
                                        @close="closeFileConditions(item, 'file')">
                                    {{ item }}
                                </el-tag>
                            </div>
                        </div>
                        <div class="arrowBox text_center" v-show="conditionsUnfoldH > 74">
                            <el-link :icon="conditionsUnfold ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :underline="false" @click="conditionsUnfold = !conditionsUnfold">{{conditionsUnfold ? '收起': '展开'}}</el-link>
                        </div>
                    </div>
                </div>
                <div class="clearAllBtn pointer" @click="clearAll">清除全部</div>
            </div>
        </div>
        <div class="plateBox">
            <div class="flex_b_t">
                <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>筛选结果</span></div>
                <div v-show="tabType == 0">
                    <el-button size="small" type="primary" plain v-if="hasPermission('home:collection')"
                               v-noMoreClick @click="collect()">收藏
                    </el-button>
                    <el-button size="small" type="primary" plain
                               v-if="hasPermission('myBorrowing:list:borrowing')"
                               @click="borrow()">借阅
                    </el-button>
                    <el-button-group class="m_l1">
                        <el-button size="small" :type="showList ? '' : 'primary'" @click="showList = false">卡片
                        </el-button>
                        <el-button size="small" :type="showList ? 'primary' : ''" @click="showList = true">列表
                        </el-button>
                    </el-button-group>
                </div>
            </div>
            <!--卡片-->
            <el-row v-if="loading && !showList" v-loading="loading" element-loading-text="加载中"
                    class="loadingBox"></el-row>
            <div class="noData" v-if="dateList.length == 0 && !showList">暂无数据</div>
            <div v-if="!showList && dateList.length > 0" class="classifyDataBox m_t1">
                <div class="flex1 classifyDataBox">
                    <div class="item pointer" v-for="(item, index) in dateList" :key="index" @click="view(item)">
                        <div class="itemImgBox">
                            <div class="tag" :style="{background: defaultTheme}">{{ item.type_name }}</div>
                            <div class="imgNum iconfont icon-tupian">{{ item.image_num }}</div>
                            <el-image fit="contain" :src="item.thumb_path" class="itemImg">
                                <el-image slot="placeholder" fit="cover" class="itemImg"
                                          :src="require('@/assets/img/collPhoto.png')"></el-image>
                                <el-image slot="error" fit="cover" class="itemImg"
                                          :src="require('@/assets/img/collPhoto.png')"></el-image>
                            </el-image>
                        </div>
                        <div class="info">
                            <el-tooltip effect="dark"
                                        :content="item.div_type != null && item.div_type == '10' ? item.collection_name : item.resource_name"
                                        placement="top-start">
                                <div class="name omit">
                                    {{
                                    item.div_type != null && item.div_type == '10' ? item.collection_name :
                                    item.resource_name
                                    }}
                                </div>
                            </el-tooltip>
                            <div class="flex_l_t explainBox" v-if="item.resource_keys">
                                <el-tooltip effect="dark" :content="item.resource_keys[0]" placement="top"
                                            :disabled="!item.resource_keys[0]">
                                    <span class="explain0 omit"
                                          v-show="item.resource_keys[0]">{{ item.resource_keys[0] }}</span>
                                </el-tooltip>
                                <el-tooltip effect="dark" :content="item.resource_keys[1]" placement="top"
                                            :disabled="!item.resource_keys[1]">
                                    <span class="explain1 omit"
                                          v-show="item.resource_keys[1]">{{ item.resource_keys[1] }}</span>
                                </el-tooltip>
                                <el-tooltip effect="dark" :content="item.resource_keys[2]" placement="top"
                                            :disabled="!item.resource_keys[2]">
                                    <span class="explain2 omit"
                                          v-show="item.resource_keys[2]">{{ item.resource_keys[2] }}</span>
                                </el-tooltip>
                            </div>
                            <div class="flex_l_t explainBox" v-else></div>
                            <div class="numberBox">
                                <el-tooltip effect="dark" :content="item.collection_num" placement="top-start"
                                            :disabled="!item.collection_num">
                                    <div class="numberItemBox">
                                        <span class="numberTit" v-show="item.collection_num">编号：</span>
                                        <span class="number omit"
                                              v-show="item.collection_num">{{ item.collection_num }}</span>
                                    </div>
                                </el-tooltip>
                                <div class="itemBox">
                                    <span class="iconfont icon-ai235"
                                          v-if="hasPermission('myBorrowing:list:borrowing')"
                                          :style="{color: item.isBorrow && item.borrowing && item.borrowing.applyStatus == 2? defaultTheme : ''}"
                                          @click.stop="borrow(item)"></span>
                                    <span class="iconfont icon-shoucang-shoucang"
                                          v-show="hasPermission('home:collection')"
                                          :style="{color: item.isCollect ? defaultTheme : ''}"
                                          @click.stop="collect(item)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--资源列表-->
            <el-table v-show="showList && tabType == 0"
                      :data="dateList" v-loading="loading" @selection-change="selectionChangeHandle"
                      class="table" ref="tableBox" row-key='id'>
                <el-table-column type="selection" width="50" :reserve-selection='true' fixed/>
                <el-table-column v-for="(item,index) in config" :key="index" :prop="item.fieldEname"
                                 :label="item.fieldName"
                                 min-width="130" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-if="item.fieldEname=='resource_types'
                            && scope.row.resource_types!=null
                            && scope.row.resource_types.length > 0">
                            <img v-show="scope.row.resource_types.indexOf('0') !== -1"
                                 :src="require('@/assets/img/threeIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('1') !== -1"
                                 :src="require('@/assets/img/imgIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('2') !== -1"
                                 :src="require('@/assets/img/audioIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('3') !== -1"
                                 :src="require('@/assets/img/mp4Icon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('4') !== -1"
                                 :src="require('@/assets/img/xlsIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('5') !== -1"
                                 :src="require('@/assets/img/word.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('6') !== -1"
                                 :src="require('@/assets/img/zipIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('7') !== -1"
                                 :src="require('@/assets/img/document.png')" class="resourcesIcon">
                        </div>
                        <el-image v-else-if="item.fieldEname=='thumb_path'" :src="scope.row.thumb_path" fit="contain"
                                  class="tableImg">
                            <el-image slot="placeholder" fit="contain" class="tableImg"
                                      :src="require('@/assets/img/collPhoto.png')"></el-image>
                            <el-image slot="error" fit="contain" class="tableImg"
                                      :src="require('@/assets/img/collPhoto.png')"></el-image>
                        </el-image>
                        <span v-else-if="item.fieldEname=='resource_keys'
                            && scope.row.resource_keys!=null
                            && scope.row.resource_keys.length > 0">
                            <span style="margin-right: 5px"
                                  v-for="(item,index) in scope.row.resource_keys">{{ item }};</span>
                        </span>
                        <span v-else-if="item.fieldEname=='collection_num'">
                            {{ scope.row.general_num ? scope.row.general_num : scope.row.help_num }}
                        </span>
                        <span v-else v-show="scope.row[item.fieldEname]">{{ scope.row[item.fieldEname] }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="260">
                    <template slot-scope="scope">
                        <el-button type="text" @click="view(scope.row)">查看</el-button>
                        <el-button type="text"
                                   v-if="scope.row.isCollect==null && hasPermission('home:collection')"
                                   @click="collect(scope.row)">收藏
                        </el-button>
                        <el-button type="text" @click="collect(scope.row)"
                                   v-if="scope.row.isCollect">取消收藏
                        </el-button>
                        <el-button type="text"
                                   v-if="hasPermission('myBorrowing:list:borrowing') && (scope.row.borrowing==null || scope.row.borrowing.applyStatus != 2)"
                                   @click="borrow(scope.row)">借阅
                        </el-button>
                        <el-button type="text"
                                   v-if="scope.row.borrowing && scope.row.borrowing.applyStatus == 2 && !scope.row.borrowing.onExpire"
                                   @click="closeBorrow(scope.row)">归还
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--文件列表-->
            <el-table v-show="tabType == 1"
                      :data="dateList" v-loading="loading" @selection-change="selectionChangeHandle"
                      class="table" ref="tableBox" row-key='id'>
                <el-table-column type="selection" width="50" :reserve-selection='true' fixed/>
                <el-table-column prop="fileName" label="文件名称" width="160" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>
                            <el-image slot="error" fit="cover" class="fileFormatImg" :src="getFileFormatIcon(scope.row.fileFormat)"></el-image>
                            <span class="fileName">{{scope.row.fileName}}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="fileFormat" label="文件格式" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="fileDesc" label="文件描述" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="fileSizeFormat" label="文件大小" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="resourceName" label="所属资源" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="resourceTypeName" label="资源类型" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="generalNum" label="藏品编号" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.generalNum ? scope.row.generalNum : scope.row.helpNum }}
                    </template>
                </el-table-column>
                <el-table-column prop="collectionName" label="藏品名称" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" @click="view(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--借阅-->
        <BorrowForm ref="borrowForm" @refreshDataList="search"></BorrowForm>
    </div>
</template>

<script>
    import BorrowForm from './BorrowForm' // 借阅弹窗

    export default {
        components: {BorrowForm},
        data() {
            return {
                tabType: '0',
                srhStr: '',
                showList: 1,
                fileTypeOpt: [],
                unlimitedFlag: true,
                fileTypeFlag: true,
                fileTypeUnfold: false,
                conditionsUnfold: false,
                conditionsUnfoldH: 0,
                resourceCheckList: [],
                fileTypeCheckList: [],
                radio: '0',
                loading: false,
                dateList: [],
                config: [
                    {
                        fieldEname: 'thumb_path',
                        fieldName: '图片',
                        show: 0
                    },
                    {
                        fieldEname: 'collection_num',
                        fieldName: '藏品编号',
                    },
                    {
                        fieldEname: 'collection_name',
                        fieldName: '藏品名称',
                    },
                    {
                        fieldEname: 'resource_name',
                        fieldName: '资源名称',
                    },
                    {
                        fieldEname: 'type_name',
                        fieldName: '类别',
                        show: 0
                    },
                    {
                        fieldEname: 'resource_keys',
                        fieldName: '标签',
                        show: 0
                    }
                ],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                threeFileFormat: ['gltf', 'mtl', 'obj', 'jpg', 'stl', 'png']
            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
        },
        watch: {
            defaultTheme(val) {
                this.changeMainColor(val);
            },
            resourceCheckList() {
                setTimeout(() => {
                    this.conditionsUnfoldH = this.$refs.conditionsUnfoldBox.offsetHeight
                }, 500)
            },
            fileTypeCheckList() {
                setTimeout(() => {
                    this.conditionsUnfoldH = this.$refs.conditionsUnfoldBox.offsetHeight
                }, 500)
            },
        },
        created() {
            if (sessionStorage.getItem('homeTabType')) {
                this.tabType = sessionStorage.getItem('homeTabType')
                sessionStorage.removeItem('homeTabType')
            }
            if (sessionStorage.getItem('homeSrh')) {
                this.srhStr = sessionStorage.getItem('homeSrh')
                sessionStorage.removeItem('homeSrh')
            }
            if (sessionStorage.getItem('resourceType') != null
                && sessionStorage.getItem('resourceType') != ''
                && sessionStorage.getItem('resourceType') != 'null') {
                this.unlimitedFlag = false
                this.resourceCheckList.push(sessionStorage.getItem('resourceType'))
            }
            sessionStorage.removeItem('resourceType')
            let srhData = JSON.parse(sessionStorage.getItem('retrievedResultOpt'))
            if (srhData != null && srhData) {
                this.srhStr = srhData.srhStr
                this.tabType = srhData.tabType
                this.radio = srhData.radio
                this.fileTypeOpt = srhData.fileTypeOpt
                this.resourceCheckList = srhData.resourceCheckList
                this.fileTypeCheckList = srhData.fileTypeCheckList
                this.showList = srhData.showList
                this.pageNo = parseInt(srhData.pageNo);
            }
            sessionStorage.removeItem('retrievedResultOpt')
        },
        mounted() {
            this.search()
            this.$nextTick(() => {
                this.changeMainColor(this.defaultTheme);
            })
        },
        methods: {
            // 搜索
            search(type) {
                if (type == 1) {
                    this.pageNo = 1
                }
                this.loading = true
                this.dateList = []
                let api = this.api.dyyg.adminQueryPage
                let p = {
                    current: this.pageNo,
                    size: this.pageSize,
                    nameOrKeyName: this.srhStr,
                    resourceTypes: this.resourceCheckList
                }
                if (this.tabType == 1) {
                    api = this.api.dyyg.queryPageFromFile
                    p.fileFormat = this.fileTypeCheckList
                }
                this.$axios(api, p, "post").then((res) => {
                    if (res.status) {
                        if (this.tabType == 0) {
                            var list = []

                            if (res.data[0][10] != null && res.data[0][10].length > 0) {
                                list = res.data[0][10]
                            }

                            if (res.data[0][11] != null && res.data[0][11].length > 0) {
                                res.data[0][11].map(item => {
                                    list.push(item)
                                })
                            }

                            for (let i = 0; i < 8; i++) {
                                if (res.data[0][i] != null && res.data[0][i].length > 0) {
                                    res.data[0][i].map(item => {
                                        list.push(item)
                                    })
                                }
                            }
                            this.dateList = list
                            this.total = res.data[1][1]
                        } else {
                            this.dateList = res.data.records
                            this.total = parseInt(res.data.total)
                        }
                        this.loading = false
                    }
                })
            },
            // 资源-关闭单个已选条件
            closeConditions(item) {
                if (item == 10 || item == 11) {
                    this.radio = '0'
                }
                this.resourceCheckList = this.resourceCheckList.filter(v => item != v)
                if (!this.resourceCheckList.length) {
                    this.unlimitedFlag = true
                }
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                this.search(1)
            },

            // 文件-关闭单个已选条件
            closeFileConditions(item, type) {
                if (type == 'file') {
                    this.fileTypeCheckList = this.fileTypeCheckList.filter(v => item != v)
                    if (!this.fileTypeCheckList.length) {
                        this.fileTypeFlag = true
                    }
                } else {
                    this.resourceCheckList = this.resourceCheckList.filter(v => item != v)
                    if (!this.resourceCheckList.length) {
                        this.unlimitedFlag = true
                        this.fileTypeFlag = true
                    }
                    this.fileTypeOpt = []
                    this.resourceCheckList.map(item => {
                        if(item == 0) {
                            this.fileTypeOpt = this.fileTypeUnique(this.fileTypeOpt.concat(this.threeFileFormat))
                        } else {
                            this.fileTypeOpt = this.fileTypeUnique(this.fileTypeOpt.concat(this.getResourceUploadFileFormat(item)))
                        }
                    })
                    this.fileTypeCheckList = this.fileTypeOpt.filter(item => this.fileTypeCheckList.some(ele => ele === item))
                }
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                this.search(1)
            },

            // 选择资源属性
            attributeType() {
                this.resourceCheckList = this.resourceCheckList.filter(item => {
                    return item != 10 && item != 11
                })
                if (this.radio != 0) {
                    this.resourceCheckList.push(this.radio)
                }
                this.search(1)
            },

            // 文件类型不限
            selFileTypeFlag() {
                this.fileTypeCheckList = []
                this.fileTypeFlag = true
                this.search(1)
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
            },

            // 资源不限
            selUnlimited() {
                this.resourceCheckList = this.resourceCheckList.filter(item => item == 10 || item == 11)
                this.unlimitedFlag = true
                this.fileTypeFlag = true
                this.fileTypeCheckList = []
                this.fileTypeOpt = []
                this.search(1)
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
            },
            // 选择资源
            resourceChange(val) {
                if (this.resourceCheckList.length > 0) {
                    this.unlimitedFlag = false
                } else {
                    this.unlimitedFlag = true
                }
                if (this.tabType == 1) {
                    this.fileTypeOpt = []
                    val.map(item => {
                        if(item == 0) {
                            this.fileTypeOpt = this.fileTypeUnique(this.fileTypeOpt.concat(this.threeFileFormat))
                        } else {
                            this.fileTypeOpt = this.fileTypeUnique(this.fileTypeOpt.concat(this.getResourceUploadFileFormat(item)))
                        }
                    })
                    this.fileTypeCheckList = this.fileTypeOpt.filter(item => this.fileTypeCheckList.some(ele => ele === item))
                    if (!this.fileTypeCheckList.length) {
                        this.fileTypeFlag = true
                    }
                }
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                this.search(1)
            },
            // 选择文件类型
            fileTypeChange() {
                if (this.fileTypeCheckList.length > 0) {
                    this.fileTypeFlag = false
                } else {
                    this.fileTypeFlag = true
                }
                this.search(1)
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
            },
            // 文件类型去重
            fileTypeUnique(arr) {
                return arr.filter(function (item, index, arr) {
                    return arr.indexOf(item, 0) === index;
                });
            },
            // 清除全部
            clearAll() {
                if (this.tabType == 1) {
                    this.showList = 1
                }
                this.resourceCheckList = []
                this.fileTypeCheckList = []
                this.fileTypeOpt = []
                this.unlimitedFlag = true
                this.fileTypeFlag = true
                this.radio = '0'
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                this.search(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 收藏
            collect(row) {
                if (!row && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let root = [];
                if (row != null) {
                    root = [{
                        id: row.homeCollectId,
                        foreignKeyId: row.id,
                        foreignType: row.div_type != null ? row.div_type : row.resource_type,
                        delFlag: row.homeCollectId != null ? 1 : 0
                    }]
                } else {
                    let flag = false;
                    this.dataListSelections.map(item => {
                        if (!flag && item.homeCollectId == null) {
                            flag = true
                        }
                        root.push({
                            id: item.homeCollectId,
                            foreignKeyId: item.id,
                            foreignType: item.div_type != null ? item.div_type : item.resource_type,
                        })
                    })
                    if (!flag) {
                        this.$message.success("该藏品已全部被收藏")
                        return
                    }
                }
                let type = 1
                if (row && row.isCollect) {
                    type = 0
                }
                this.$confirm(`确定${type ? '加入' : '取消'}收藏吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.dyyg.collectionUpdateById, root, 'post').then(data => {
                        if (data.status) {
                            this.$message.success(`${type ? '收藏' : '取消'}成功`)
                        } else {
                            this.$message.error(`${type ? '收藏' : '取消'}成功`)
                        }
                        this.$refs.tableBox.clearSelection()
                        this.search()
                    })
                })

            },
            closeBorrow(data) {
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确定归还资源？'),
                        h('p', {style: 'color: #999999; font-size: 12px'}, '归还后，借阅的资源权限将被收回')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.$axios(this.api.dyyg.giveBack, [{
                        id: data.borrowing.id,
                        onExpire: 1,
                    }], 'post').then(data => {
                        if (data.status) {
                            this.$message.success('归还成功')
                        } else {
                            this.$message.error('归还失败')
                        }
                        this.search()
                    })
                });
            },
            // 借阅
            borrow(data) {
                if (!data && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let root = []
                let item = ''
                if (data) {
                    item = JSON.parse(JSON.stringify(data))
                } else {
                    item = null;
                }
                if (item == null) {
                    root = JSON.parse(JSON.stringify(this.dataListSelections))
                    root = root.filter(item => item.isBorrow == null)
                    if (root != null && root.length > 0) {
                        root.map(item => {
                            this.$set(item, "foreign_key_id", item.id)
                            this.$set(item, "divType", item.div_type != null ? item.div_type : item.resource_type)
                            item.id = null
                        })
                    }
                } else {
                    if (item.borrowing && item.borrowing.applyStatus == 0) {
                        this.$message.warning("该数字资源正在走借阅流程，请在我的借阅中查看！")
                        return
                    }
                    if (item.borrowing != null && item.borrowing.applyStatus == 2) {
                        this.closeBorrow(item)
                        return
                    } else {
                        this.$set(item, "foreign_key_id", item.id)
                        this.$set(item, "divType", item.div_type != null ? item.div_type : item.resource_type)
                        item.id = null
                    }
                    root.push(item)
                }
                if (root != null && root.length == 0) {
                    this.$message.warning("该文物已全部被借阅！")
                    return
                }
                this.$refs.borrowForm.init(root)
            },
            // 查看
            view(row) {
                let opt = {
                    srhStr: this.srhStr,
                    tabType: this.tabType,
                    radio: this.radio,
                    fileTypeOpt: this.fileTypeOpt,
                    resourceCheckList: this.resourceCheckList,
                    fileTypeCheckList: this.fileTypeCheckList,
                    showList: this.showList,
                    pageNo: this.pageNo,
                }
                sessionStorage.setItem('retrievedResultOpt', JSON.stringify(opt))
                this.$set(row, "resourceCheckList", this.resourceCheckList)
                sessionStorage.setItem('dizhi', '/dyyg/home/retrievedResult')
                let val = JSON.parse(JSON.stringify(row))
                if (this.tabType == 1) {
                    val.id = val.resourceId
                }
                this.$router.push({
                    path: '/dyyg/home/retrievedDetails',
                    query: val,
                });
            },
            // 目录每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.search()
            },
            // 目录当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .tableImg {
        width: 100px;
        height: 100px;
    }

    .fileTypeBox .el-checkbox {
        width: 6% !important;
    }

    .unlimited {
        font-size: 1.13rem;
        margin-right: 2rem;
        padding: 0.38rem 1.1rem;
        border-radius: 0.25rem;
    }

    .resourcesIcon {
        width: 2rem;
        margin: 0 0.5rem;
    }

    //首页
    .dyygHome {
        margin: -10px;
        overflow-y: auto;
        height: calc(100% + 20px) !important;
        background-image: url('../../../../assets/img/retrievalBg.png');
        background-position: top center;
        background-size: cover;

        .loadingBox {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
        }

        .bg0, .bg6 {
            background: #A82126;
        }

        .bg1, .bg7 {
            background: #21A874;
        }

        .bg2, .bg8 {
            background: #F57F38;
        }

        .bg3, .bg9 {
            background: #9676CC;
        }

        .bg4, .bg10 {
            background: #2378C7;
        }

        .bg5, .bg11 {
            background: #F6BD4E;
        }

        .srhBox {
            padding: 3.75rem 0 2.88rem;
        }

        ::placeholder {
            color: #BFBFBF;
        }

        .classifyBox {
            padding: 0 1.25rem;
            margin-bottom: 1.88rem;
        }

        .classifyItem {
            background-size: 100% 100%;
            color: #ffffff;
            text-align: center;
            padding: 0 1.75rem;

            .title {
                background: url('../../../../assets/img/classifyBox.png');
                background-size: 100% 100%;
                width: 12.75rem;
                height: 5.63rem;
                line-height: 5.63rem;
                font-size: 2.5rem;
                margin: 8.7rem 0 0.38rem;
            }

            .piece {
                font-size: 1.5rem;
                opacity: 0.5;
            }

            .moreBtn {
                background: url('../../../../assets/img/buttonBox.png');
                background-size: 100% 100%;
                width: 8.75rem;
                height: 2.5rem;
                line-height: 2.5rem;
                font-size: 1.13rem;
                margin: 5.63rem auto;
            }
        }

        .classifyItem0, .classifyItem6 {
            background: url("../../../../assets/img/classifyBg1.png");
        }

        .classifyItem1, .classifyItem7 {
            background: url('../../../../assets/img/classifyBg2.png');
        }

        .classifyItem2, .classifyItem8 {
            background: url('../../../../assets/img/classifyBg3.png');
        }

        .classifyItem3, .classifyItem9 {
            background: url('../../../../assets/img/classifyBg4.png');
        }

        .classifyItem4, .classifyItem10 {
            background: url('../../../../assets/img/classifyBg5.png');
        }

        .classifyItem5, .classifyItem11 {
            background: url('../../../../assets/img/classifyBg6.png');
        }

        .classifyDataBox {
            background: #ffffff;
            padding: 0 0.5%;

            .item {
                display: inline-block;
                width: 19%;
                margin: 1rem 0.5%;
                border-radius: 0.5rem;
                box-shadow: 0rem 0rem 1rem 0rem rgba(215, 205, 205, 0.75);

                .tag {
                    position: absolute;
                    left: 0;
                    top: 1.25rem;
                    font-size: 1rem;
                    padding: 0.25rem 1rem;
                    color: #ffffff;
                    border-radius: 0rem 1rem 1rem 0rem;
                    z-index: 1;
                }

                .imgNum {
                    position: absolute;
                    right: 0.69rem;
                    bottom: 8%;
                    z-index: 1;
                    padding: 0.05rem 0.88rem;
                    color: #ffffff;
                    background: rgba(0, 0, 0, 0.15);
                    border-radius: 1rem;
                    font-size: 1rem;
                    border: 1px solid #FFFFFF;
                }

                .playIcon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    color: rgba(255, 255, 255, 0.5);
                    font-size: 3rem;
                }

                .icon-tupian:before {
                    margin-right: 0.38rem;
                }

                .itemImgBox {
                    position: relative;
                }

                .itemImg {
                    height: 15rem;
                    width: 100%;
                    border-radius: 0.5rem;
                }

                .info {
                    padding: 1rem 1rem;
                    font-size: 1.25rem;
                    color: #000000;
                }

                .explainBox {
                    height: 2.7rem;
                }

                .explain0, .explain1, .explain2 {
                    padding: 0.25rem 0.75rem;
                    background: rgba(168, 33, 38, 0.12);
                    color: #A82126;
                    font-size: 0.88rem;
                    border-radius: 0.25rem;
                    margin-top: 0.75rem;
                    display: inline-block;
                    //width: 31.333%;
                    margin-right: 2%;
                    text-align: center;
                }

                .explain1 {
                    background: rgba(29, 94, 192, 0.12);
                    color: #1D5EC0;
                }

                .explain2 {
                    background: rgba(33, 168, 116, 0.12);
                    color: #21A874;
                }

                .numberBox {
                    margin-top: 0.8rem;
                    width: 100%;

                    .numberItemBox {
                        width: 60%;
                        display: inline-block;
                    }

                    .itemBox {
                        width: 40%;
                        display: inline-block;
                    }

                    .iconfont {
                        color: #A6A6A6;
                        font-size: 1.8rem;
                        vertical-align: middle;
                    }

                    .icon-ai235 {
                        font-size: 2.4rem;
                        margin-right: 1rem;
                    }
                }

                .numberTit {
                    color: #808080;
                }

                .number {
                    display: inline-block;
                    width: 50%;
                    vertical-align: bottom;
                }
            }
        }

        .plateBox {
            background: #FFFFFF;
            border-radius: 1.5rem;
            padding: 2.13rem 2.5rem 2.69rem;;
            color: #000000;
            margin: 0 2.44rem 2.44rem;
        }

        .plateTit {
            font-size: 1.75rem;
            margin-bottom: 2rem;

            .plateTitIcon {
                display: inline-block;
                width: 0.31rem;
                height: 1.75rem;
                background: #A82126;
                border-radius: 0.16rem;
                margin-right: 0.5rem;
            }
        }

        .queryItem {
            padding-bottom: 2.6rem;
        }

        .queryItem:last-of-type {
            padding-bottom: 0;
        }

        .tagTit {
            font-size: 1.25rem;
            width: 6.4rem;
        }

        .unlimited {
            font-size: 1.13rem;
            margin-right: 2rem;
            padding: 0.38rem 1.1rem;
            border-radius: 0.25rem;
        }

        .clearAllBtn {
            padding: 0.3rem 0.94rem;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            border-radius: 0.29rem;
            font-size: 1.13rem;
            text-align: center;
            color: #808080;
        }

        .noData {
            color: #909399;
            text-align: center;
            font-size: 1rem;
            line-height: 123px;
        }

        .resourcesIcon {
            width: 2rem;
            margin: 0 0.5rem;
        }
    }

    .arrowBox {
        .el-link, .el-icon-arrow-down {
            font-size: 12px;
        }
    }
    .unfoldBox {
        height: auto;
    }
    .unfoldBox2, .unfoldBox3 {
        max-height: 44px;
        overflow-y: hidden;
    }
    .unfoldBox3 {
        max-height: 72px;
    }
    .fileFormatImg {
        width: 15px;
        vertical-align: middle;
        margin-right: 6px;
    }

    .fileName {
        vertical-align: text-bottom;
    }
</style>
